async function supportsWebp() {
	if (!window.createImageBitmap) return false;

	const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
	const blob = await fetch(webpData).then(r => r.blob());
	return createImageBitmap(blob).then(() => true, () => false);
}

const detectsWebP = async () => {
	if (window.detectsWebP) return
	if(await supportsWebp()) {
		window.detectsWebP = true
	}
	else {
		window.detectsWebP = false
	}
	window.testingDetectsWebPDone = true
}

const preferWebFriendlyImage = imgRef => {
	let result = imgRef
	if(window.detectsWebP === undefined && !window.testingDetectsWebPDone) {
		detectsWebP()
	}
	if(!window.testingDetectsWebPDone || window.detectsWebP) {
		result = imgRef.replace(/\.[a-zA-Z]+$/gm, `.webp`)
	}
	return result
}

export {
	detectsWebP,
	preferWebFriendlyImage
}
