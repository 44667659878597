const advanceLocalStorageNameMod = 'Megamenti_'

const getItem = ({name}) => {
	const localStorage = window.localStorage
	return localStorage.getItem(`${advanceLocalStorageNameMod}${name}`)
}

const setItem = ({name, value}) => {
	const localStorage = window.localStorage
	localStorage.setItem(`${advanceLocalStorageNameMod}${name}`, value)
	return getItem({name})
}

export {
	getItem,
	setItem
}
