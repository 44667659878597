const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
	// [::1] is the IPv6 localhost address.
	window.location.hostname === '[::1]' ||
	// 127.0.0.1/8 is considered localhost for IPv4.
	window.location.hostname.match(
		/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
	)
)

const USE_STRIPE_TEST_KEYS = process.env.NODE_ENV === 'development' ? true : false

module.exports = {
	frontendAddress: isLocalhost
		? 'http://localhost:3000'
		: 'https://megamenti.com',
	backendAddress: isLocalhost
		? 'http://localhost:5001/advance-institute/us-central1/advanceInstituteF'
		: 'https://us-central1-advance-institute.cloudfunctions.net/advanceInstituteF',
	placesAPIKey: process.env.REACT_APP_PLACES_API_KEY,
	geolocationAPIKey: process.env.REACT_APP_PLACES_API_KEY,
	geocodingAPIKey: process.env.REACT_APP_GEOCODING_API_KEY,
	firebaseConfig: {
		apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
		authDomain: "advance-institute.firebaseapp.com",
		databaseURL: "https://advance-institute-default-rtdb.firebaseio.com",
		projectId: "advance-institute",
		storageBucket: "advance-institute.appspot.com",
		messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
		appId: process.env.REACT_APP_FIREBASE_APP_ID,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
	},
	STRIPE_PUBLIC_KEY: USE_STRIPE_TEST_KEYS ? process.env.REACT_APP_STRIPE_TEST_KEY : process.env.REACT_APP_STRIPE_LIVE_KEY,
}
