import {tr} from "../functions/translations/translations"

const NOTIFY_USER_ID = 'notify-user-element'
const DISPLAY_NOTIFICATION_TIME = 5000

const removeNotifyUser = () => {
	const displayingNotifyUserElement = document.getElementById(NOTIFY_USER_ID)
	if(displayingNotifyUserElement) {
		displayingNotifyUserElement.remove()
	}
}

const getExtraClasses = (callback, type) => {
	if(type === 'badNews') {
		return 'notify-user-container__bad-news-styling'
	}
	if(type === 'positive') {
		return 'notify-user-container__positive-styling'
	}
	if(callback) {
		return 'notify-user-container__callback-styling'
	}
}

const refreshPage = () => {
	window.location.reload()
}

const notifyUser = ({message, callback, type, instruction, customTime}) => {
	// instruction used instead of callback where callback is illegal in browser
	removeNotifyUser()
	const elementToSetHTMLAdjacentTo = document.getElementsByTagName('body')[0]
	let htmlToSet = `<div id="${NOTIFY_USER_ID}" class="notify-user-container ${getExtraClasses(callback, type)}">`
	htmlToSet += 	`${message}`
	htmlToSet += 	instruction === 'reload' ? ` ${tr(`Refreshing...`)}` : ''
	htmlToSet += `</div>`
	elementToSetHTMLAdjacentTo.insertAdjacentHTML("afterbegin", htmlToSet)
	setTimeout(removeNotifyUser, customTime || DISPLAY_NOTIFICATION_TIME)
	if(callback) {
		setTimeout(callback, customTime || DISPLAY_NOTIFICATION_TIME)
	}
	if(instruction === 'reload') {
		setTimeout(refreshPage, customTime || DISPLAY_NOTIFICATION_TIME)
	}
}

export {
	notifyUser
}
