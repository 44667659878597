import React from 'react'
import './AdvSeparator.css'

const AdvSeparator = (
	{
		text='',
		extraClassText=''
	}={}
) => <div className={`separator ${extraClassText}`}>
	<div className={'separator__divider'} />
	<div className={'separator__text'}>{text}</div>
	<div className={'separator__divider'} />
</div>

export default AdvSeparator
