import React from 'react'
import './AdvInput.css'

/**
 * @param  {object} props
 */

const AdvInput = (
	{
		type = 'text',
		onChange = (() => {
		}),
		value,
		placeholder,
		information,
		label,
		direction = 'horizontal',
		onKeyDown=(() => {}),
		autoFocus=false,
		inputComponent=<></>,
		extraClassNameString,
		megaHighlight
	}
) => {
	let inputField = null

	const autoFocusConditionalProp = autoFocus ? {autoFocus: true} : {}

	if(type === 'text' || type === 'email' || type === 'password') {
		inputField = <input
			type={type}
			onChange={onChange}
			value={value}
			placeholder={placeholder}
			onKeyDown={onKeyDown}
			{...autoFocusConditionalProp}
		>
		</input>
	}

	if(type === 'other') {
		inputField = inputComponent
	}

	return <div className={`adv-input-outer ${extraClassNameString ? extraClassNameString : ''}`}>
		{information && <div className={'adv-input-information'}>{information}</div>}
		<div
			className={`
				adv-input 
				${direction === 'horizontal' ? '' : 'adv-input__vertical'}
				${information && 'adv-input__with-information'}
				${megaHighlight && 'adv-input__mega-highlight'}
			`}
		>
			<label>{label}</label>
			{inputField}
		</div>
	</div>
}

export default AdvInput
