import React from 'react'
import './AdvanceLoading.css'
import Loader from "react-loader-spinner"

const AdvanceLoading = () => <div className={`advance-loading-container`}>
	<div className={'advance-is-loading-image'} />
	<Loader
		className={'advance-loading-loader'}
		type="Rings"
		color={'var(--brand-color-1)'}
		height={40}
		width={40}
	/>
</div>

export default AdvanceLoading
