const translations = {
	'Hello': {
		en: 'Hello',
		es: 'Hola',
		fr: 'Salut'
	},
	'Agree to Terms and Conditions': {
		en: 'Agree to Terms and Conditions',
		es: 'He leído los términos y condiciones (en ingles) y los acepto',
		fr: `J'ai lu les conditions générales (en anglais) et je les accepte`
	},
	'English': {
		en: 'English',
		es: 'Ingles',
		fr: 'Anglais'
	},
	'Spanish': {
		en: 'Spanish',
		es: 'Español',
		fr: 'Espagnol'
	},
	'French': {
		en: 'French',
		es: 'Frances',
		fr: 'Français'
	}
}

export const tr = (string) => {
	const lanPref = window.userLanguage || navigator.language.substr(0, 2)
	if(translations[string] && translations[string][lanPref]) {
		return translations[string][lanPref]
	}
	return string
}