import React, {useState} from 'react'
import './InFormAuthentication.css'
import {tr} from "../../functions/translations/translations"
import {
	auth,
	GoogleAuthProvider,
	fetchSignInMethodsForEmail,
	signInWithPopup,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	createUserWithEmailAndPassword
} from '../../firebaseSetup'
import Loading from "../Loading/Loading"
import {Link} from "react-router-dom"
import {notifyUser} from "../../functions/notifyUser"
import AdvButton from "../AdvButton/AdvButton"
import AdvInput from "../AdvInput/AdvInput"
import AdvSeparator from "../AdvSeparator/AdvSeparator"
import {preferWebFriendlyImage} from "../../functions/detectWebPSupport"
import {recordAction} from "../../functions/recordAction"

const GO_VALIDATE_EMAIL_TIMEOUT_AMOUNT = 1000
const EMAIL_VALIDATION_RESULTS = {
	ACCOUNT_EXISTS: 'ACCOUNT_EXISTS',
	ACCOUNT_DOES_NOT_EXIST: 'ACCOUNT_DOES_NOT_EXIST',
}
let checkWhetherAccountExistsTimeout

const provider = new GoogleAuthProvider()
provider.addScope('https://www.googleapis.com/auth/userinfo.email')

export function InFormAuthentication ({
	afterLogOrSignCallback=(() => null),
	title=tr(`Sign in / Create Account`)
}={}) {
	const [email, setEmail] = useState('')
	const [waitingForEmailValidation, setWaitingForEmailValidation] = useState(false)
	const [emailValidationResult, setEmailValidationResult] = useState(false)
	const [password, setPassword] = useState('')
	const [repeatPassword, setRepeatPassword] = useState('')
	const [termsAgreed, setTermsAgreed] = useState(false)
	const [errors, setErrors] = useState([])
	const [loading, setLoading] = useState(false)
	const [checkingEmail, setCheckingEmail] = useState(false)

	const checkWhetherAccountExists = async givenEmail => {
		setCheckingEmail(true)
		setWaitingForEmailValidation(false)
		let result
		try {
			result = await fetchSignInMethodsForEmail(auth, givenEmail)
			if (result.length > 0)
				setEmailValidationResult(EMAIL_VALIDATION_RESULTS.ACCOUNT_EXISTS)
			else
				setEmailValidationResult(EMAIL_VALIDATION_RESULTS.ACCOUNT_DOES_NOT_EXIST)
			setCheckingEmail(false)
		}
		catch (e) {
			setCheckingEmail(false)
		}
	}

	const startSignInWithGoogle = () => {
		signInWithPopup(auth, provider).then(function() {
			if(afterLogOrSignCallback) afterLogOrSignCallback()
		}).catch(function(error) {
			const errorMessage = error.message
			console.warn(errorMessage)
		})
	}

	const requestEmail = () => <>
		<AdvInput
			type={'email'}
			onChange={
				(e) => {
					const givenEmail = e.target.value
					setEmail(givenEmail)
					setErrors([])
					if(checkWhetherAccountExistsTimeout) {
						setWaitingForEmailValidation(false)
						clearTimeout(checkWhetherAccountExistsTimeout)
					}
					setWaitingForEmailValidation(true)
					if(/^[\w.=-]+@[\w.-]+\.[\w]{2,10}$/g.test(givenEmail)) {
						checkWhetherAccountExistsTimeout = setTimeout(() => checkWhetherAccountExists(givenEmail), GO_VALIDATE_EMAIL_TIMEOUT_AMOUNT)
					}
				}
			}
			onKeyDown={e => {if(e.key === 'Enter') {document.activeElement.blur()}}}
			value={email}
			placeholder={'hello@example.com'}
			label={tr('Email')}
			autoFocus={true}
		/>
		{
			checkingEmail && <div><Loading /></div>
		}
	</>

	const requestCreateAccount = () => <>
		<div className={'in-form-auth__create-password-instruction'}>
			{tr('Input a password for your new account')}
		</div>

		<div className={'in-form-auth-input-container'}>
			<AdvInput
				type={'password'}
				onChange={e => {setPassword(e.target.value); setErrors([])}}
				value={password}
				label={tr('Password')}
			/>
		</div>

		<div className={'in-form-auth-input-container'}>
			<AdvInput
				type={'password'}
				onChange={e => {setRepeatPassword(e.target.value); setErrors([])}}
				value={repeatPassword}
				label={tr('Repeat password')}
			/>
		</div>

		{
			(password && repeatPassword && password.length > 0 && password === repeatPassword)
			&& <>
				<a className={`in-form-terms-and-conditions`} href={'/terms-and-conditions'}>{ tr('Please agree to our terms and conditions') }</a>
				<div className={'agree-to-terms-buttons-section'} >
					{
						!termsAgreed && <AdvButton
							onClick={() => setTermsAgreed(true)}
							text={tr('Agree to Terms and Conditions')}
						/>
					}
					{
						<AdvButton
							extendClassNameString={'auth-continue'}
							onClick={handleSignUp}
							text={tr('Create Account and Continue')}
							loading={loading}
						/>
					}
				</div>
			</>
		}
	</>

	const requestEnterPassword = () => <>
		<div className={'in-form-auth-heading-verb'}>
			{tr('Welcome back')}
		</div>


		<div className={'in-form-auth-input-container'}>
			<AdvInput
				type={'password'}
				onKeyDown={e => {if(e.keyCode === 13) {handleLogIn()}}}
				onChange={e => {setPassword(e.target.value); setErrors([])}}
				value={password}
				label={tr('Password')}
			/>
		</div>
		<div
			className={'inform-auth-clickable-span'}
			onClick={async () => {
				await sendPasswordResetEmail(auth, email)
				notifyUser({message: tr(`Instructions on how to reset your password have been sent to the email address you provided.`)})
			}}
		>{ tr('I forgot my password') }</div>
		<div
			className={'inform-auth-clickable-span'}
			onClick={() => {
			setEmail('')
			setPassword('')
			setRepeatPassword('')
			setTermsAgreed(false)
		}}>{ tr('Use a different email address') }</div>
		{
			(password && password.length > 0)
			&& <div style={{textAlign: 'right'}}>
				<AdvButton
					extendClassNameString={'auth-continue'}
					text={ loading ? <Loading inButton/> : tr('Continue') }
					onClick={handleLogIn}/>
			</div>
		}
	</>

	const handleSignUp = async () => {
		recordAction('event', 'handle-sign-up')
		setLoading(true)
		const errs = []
		if (password.length < 3) {
			errs.push(tr('Please set a password of an appropriate length'))
		}
		if (password !== repeatPassword) {
			errs.push(tr('Passwords must match'))
		}
		if (!termsAgreed) {
			errs.push(tr('You must agree to our terms and conditions to continue'))
		}
		if(errs.length > 0) {
			setErrors(errs)
			setLoading(false)
			return
		}
		try {
			await createUserWithEmailAndPassword(auth, email, password)
			setLoading(false)
			afterLogOrSignCallback()
		}
		catch(e) {
			setLoading(false)
			setErrors([e.message])
		}
	}

	const handleLogIn = async () => {
		recordAction('event', 'handle-log-in')
		setLoading(true)
		try {
			await signInWithEmailAndPassword(auth, email, password)
			setLoading(false)
			afterLogOrSignCallback()
		}
		catch(e) {
			setLoading(false)
			console.warn(e)
			setErrors([e.message])
		}
	}

	const renderSignInWithGoogle = () => <div className={'auth-google-sign-in-container'}>
		<div className={'sign-in-with-google-button-and-image'}>
			<AdvButton
				fullWidth
				text={tr('Continue with Google')}
				onClick={startSignInWithGoogle}
				icon={
					<div
						className={'sign-in-with-google-image'}
						style={{backgroundImage: `url("${preferWebFriendlyImage('/google-logo.png')}")`}}>
					</div>
				}
				iconReverse
			/>
		</div>
		<div className={`sign-in-with-google-terms-and-conditions`}>
			{tr('Continuing indicates your agreement to our ')}
			<Link to={'/terms-and-conditions'} className={`sign-in-with-google-terms-and-conditions-link`}>
				{tr('terms')}
			</Link>
		</div>
	</div>

	const renderSignInWithEmail = () => <div className={'auth-email-sign-in-container'}>
		{ requestEmail() }
		{ (email && email.length > 0 && emailValidationResult && !waitingForEmailValidation && emailValidationResult === EMAIL_VALIDATION_RESULTS.ACCOUNT_DOES_NOT_EXIST) && requestCreateAccount() }
		{ (email && email.length > 0 && emailValidationResult && !waitingForEmailValidation && emailValidationResult === EMAIL_VALIDATION_RESULTS.ACCOUNT_EXISTS) && requestEnterPassword() }
		{
			(errors && errors.length > 0) &&
			<div className={'auth-errors'}>
				{
					errors && errors.map(e => e)
				}
			</div>
		}
	</div>
	
	return (
		<div className={'auth-container'}>
			{title && <h2>{title}</h2>}
			{
				email.length === 0 && <>
					{renderSignInWithGoogle()}
					<AdvSeparator extraClassText={'in-form-auth-separator'} text={'OR'}/>
				</>
			}
			{ renderSignInWithEmail() }
		</div>
	)
}
