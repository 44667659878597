import React from 'react'
import './Auth.css'
import {tr} from "../../functions/translations/translations"
import {InFormAuthentication} from "../../Components/Authentication/InFormAuthentication"
import {useNavigate} from "react-router-dom"

export default function Auth (props) {
	const {userOrNull} = props
	const navigate = useNavigate()
	document.title = tr(`My Classes`)
	const renderAuth = () => <div className={'auth-wrapper'}>
		<InFormAuthentication/>
	</div>
	
	const redirectToAccount = () => {
		navigate('/d')
		return null
	}

	return userOrNull
		? redirectToAccount()
		: renderAuth()
}
