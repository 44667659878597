import {backendAddress} from "./credentials"
import {getItem} from "./functions/localStorage"

const advanceGet = async location => {
	const userAuthToken = getItem({name: 'userAuthToken'}) || 0;
	let result = await fetch(
		`${backendAddress}/${location}`,
		{
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${userAuthToken}`
			}
		}
	)
	result = await result.json()
	return result
}

const advancePost = async (location, parameters) => {
	const userAuthToken = getItem({name: 'userAuthToken'}) || 0
	let result = await fetch(
		`${backendAddress}/${location}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${userAuthToken}`
			},
			body: JSON.stringify(parameters)
		}
	)
	if(result.status !== 200) {
		console.warn(result.status)
		return null
	}
	result = await result.json()
	return result
}

const sendContactFormMessage = async parameters => {
	return advancePost('general/contact-form', parameters)
}

const createCustomerForUID = async parameters => {
	return advancePost('payments/create-customer-for-uid', parameters)
}

const createPriceObject = async parameters => {
	return advancePost('payments/create-price-object', parameters)
}

const cancelSubscription = async parameters => {
	return advancePost('payments/cancel-subscription', parameters)
}

const getTimezone = async parameters => {
	return advancePost('general/get-timezone-for-location', parameters)
}

export {
	sendContactFormMessage,
	createCustomerForUID,
	createPriceObject,
	cancelSubscription,
	getTimezone,
	advanceGet,
	advancePost
}
