import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import './Loading.css'

export default function Loading (props) {
	const {inButton, onWhite, color} = props
	return <div className={`loader-container ${inButton ? 'loading-container__in-button': ''}`}>
		<Loader
			type="Rings"
			color={color ? color : (onWhite ? 'var(--brand-color-1)' : '#ccc')}
			height={inButton ? 20 : 100}
			width={inButton ? 20 : 100}
		/>
	</div>
}
