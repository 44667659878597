import React from 'react'
import './AdvButton.css'
import Loader from "react-loader-spinner"
import ExpandMore from "@material-ui/icons/ExpandMore"

/**
 * @param  {object} props
 * @param props.onClick
 * @param props.text
 * @param props.icon
 * @param props.selected
 * @param props.loading
 * @param props.extendClassNameString
 * @param props.fullWidth
 *
 */
const AdvButton = (
	{
		onClick=()=>{},
		text='',
		icon=null,
		iconReverse=false,
		selected,
		loading,
		extendClassNameString='',
		fullWidth,
		showExpandable=false,
		id='',
		extraOnClickAction=null
	}={}
) => {

	const renderMain = () => <button
		id={id}
		onClick={() => {
			extraOnClickAction && extraOnClickAction()
			onClick()
		}}
		className={`
			adv-button 
			${fullWidth ? 'adv-button__full-width' : ''} 
			${selected ? 'adv-button__selected' : ''} 
			${iconReverse ? 'adv-button__icon-reverse' : ''} 
			${extendClassNameString}
		 `}>
		{
			loading && <Loader className={'adv-button-loading-state-loader'}  type="Rings"/>
		}
		{
			!loading && <>
				<div className={`adv-button__text ${showExpandable ? 'adv-button__text__expandable' : ''}`}>
					{text}
				</div>
				{icon && <div>{icon}</div>}
			</>
		}
		{showExpandable && <ExpandMore className={'adv-button-expandable'} />}
	</button>

	return renderMain()
}

export default AdvButton
