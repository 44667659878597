import React, {useEffect, useState} from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"


  /*


  KNOWN BUG

  there's a problem with a something.clear() method after payment processes
  it sets off a warning notification that I display to the user in paymentsectionjs
  Not sure which of the two it is coming from
  But in any case, I'm pretty sure it's happening because the state is updating which is re-rendering the page which Stripe is trying to clear the card number
  but there's no card number to clear because the component already re-rendered and so the field / value is undefined / empty / stripe can't find the element
  In any case --- - -- -- probably should stop Stripe from being rerendered there.





   */


import './App.css'
import { auth, db, ref, set, onValue, onAuthStateChanged } from './firebaseSetup'
import ScrollToTop from "./Components/ReactRouterScrollToTop/ReactRouterScrollToTop"
import wrapInSuspense from "./wrapInSuspense"
import {detectsWebP} from "./functions/detectWebPSupport"
import AdvanceLoading from "./Components/AdvanceLoading/AdvanceLoading"
import {getItem, setItem} from "./functions/localStorage"
import Auth from "./Pages/Auth/Auth"
import {recordAction, recordActionInAdvanceServer, uploadStringFileToFirebaseStorage} from "./functions/recordAction"

let lastUserObject = null

const Landing = React.lazy(() => import("./Pages/Public/Landing/Landing"))
const Footer = React.lazy(() => import("./Components/Footer/Footer"))
const NavBar = React.lazy(() => import("./Components/NavBar/NavBar"))
const TermsAndConditions = React.lazy(() => import('./Pages/Public/Legal/TermsAndConditions'))
const TermsAndConditionsOfSupply = React.lazy(() => import('./Pages/Public/Legal/TermsAndConditionsOfSupply'))
const PrivacyPolicy = React.lazy(() => import('./Pages/Public/Legal/PrivacyPolicy'))
const AcceptableUse = React.lazy(() => import('./Pages/Public/Legal/AcceptableUse'))
const Press = React.lazy(() => import("./Pages/Public/StandardAbout/Press/Press"))
const Approach = React.lazy(() => import("./Pages/Public/StandardAbout/Approach/Approach"))
const Careers = React.lazy(() => import("./Pages/Public/StandardAbout/Careers/Careers"))
const Payment = React.lazy(() => import("./Pages/Public/StandardAbout/Payment/Payment"))
const FAQ = React.lazy(() => import("./Pages/Public/StandardAbout/FAQ/FAQ"))

const Dashboard = React.lazy(() => import("./Pages/Dashboard/Dashboard"))
const CategoryList = React.lazy(() => import("./Components/CategoryList/CategoryList"))
const Page = React.lazy(() => import("./Components/Inputter/Inputter"))
const WorkOutWeeklyAvailability = React.lazy(() => import("./Components/WeeklyAvailability/WorkOutWeeklyAvailability"))
const WeeklyAvailabilityChart = React.lazy(() => import("./Components/WeeklyAvailability/WeeklyAvailabilityChart"))
const SubjectPage = React.lazy(() => import("./Pages/SubjectPage/SubjectPage"))
const BookingCompletePage = React.lazy(() => import("./Pages/BookingCompletePage/BookingCompletePage"))
const ConfirmAndBook = React.lazy(() => import("./Components/ConfirmAndBook/ConfirmAndBook"))
const Learning = React.lazy(() => import("./Pages/Public/Learning/Learning"))
const ChatContainer = React.lazy(() => import("./Components/Chat/ChatContainer"))
const AdminChatContainer = React.lazy(() => import("./Components/Chat/AdminChatContainer"))
const AdminPanel = React.lazy(() => import("./Components/AdminPanel/AdminPanel"))
const StoryBook = React.lazy(() => import("./Components/StoryBook/StoryBook"))
// const Stream = React.lazy(() => import("./Pages/Public/Stream/Stream"))
const Video = React.lazy(() => import("./Pages/Public/Video/Video"))
const HowItWorks = React.lazy(() => import("./Pages/Public/HowItWorks/HowItWorks"))

detectsWebP()

const App = () => {
  const localStorageUserCurrency = getItem({name: 'userCurrency'})
  const [userOrNull, setUserOrNull] = useState(null)
  const [userData, setUserData] = useState(null)
  const [loadingFirebaseData, setLoadingFirebaseData] = useState(true)
  const [searchInput, setSearchInput] = useState('')
  const [publicData, setPublicData] = useState({})
  const [adminUsersData, setAdminUsersData] = useState({})
  const [theme, setTheme] = useState({})
  const [showAdvanceLoading, setShowAdvanceLoading] = useState(true)
  const [userCurrency, setUserCurrency] = useState(
    localStorageUserCurrency || ((userData && userData.currency) ? userData.currency : null)
  )

  const updateCurrency = async () => {
    /*
      Max 30,000 per day, I believe
    */
    console.info('Fetching user currency...')
    let country = null

    try {
      console.info(`Fetching user currency...`)
      const result = await (await fetch('https://api.geoapify.com/v1/ipinfo?&apiKey=460808e9e5b449eeb7b068947d8253f2')).json()
      country = result.country
      console.info(`Got country ${JSON.stringify(country)}`)
    }
    catch(e) {
      console.warn(`Error getting user country ${JSON.stringify(e)}`)
    }

    if(country.currency && userCurrency !== country.currency) {
      setUserCurrency(country.currency)
      setItem({name: 'userCurrency', value: country.currency})
    }
  }

  const pingUserPresence = () => {
    recordAction('event', 'ping')
  }

  /*const recordUserVisual = () => {
    window.html2canvas(document.body).then((canvas) => {
      let a = document.createElement("a")
      a.setAttribute("id", "3jv8-3bglo-dsw3rf")
      a.href = canvas.toDataURL("image/png")
      const timestamp = Date.now()
      const fileName = `screenshot-${timestamp}.png`
      // This upload directory has been disabled
      const filePath = `uploadOnly/screenshots/${fileName}`
      recordActionInAdvanceServer('event', `user-visual`, {fileName})
      uploadStringFileToFirebaseStorage({filePath, fileAsString: a.href, metadata: {contentType: 'image/png'}})
      const element = document.getElementById("3jv8-3bglo-dsw3rf")
      if(element) {
        element.parentNode.removeChild(element)
      }
    })
  }*/

  useEffect(() => {
    const intervalId = setInterval(pingUserPresence, 5000)

    return () => clearInterval(intervalId)
  }, [publicData])

  /*useEffect(() => {
    const intervalId = setInterval(recordUserVisual, 15000)
    const timeoutId = setTimeout(recordUserVisual, 3000)
    recordUserVisual()

    return () => {
      clearInterval(intervalId)
      clearTimeout(timeoutId)
    }
  }, [publicData])*/

  useEffect(
    () => {
      if(!localStorageUserCurrency && userCurrency === null) {
        updateCurrency()
      }
    }, [userOrNull]
  )

  useEffect(
    () => {
      if(userData && userData.currency) {
        setItem({name: 'userCurrency', value: userData.currency})
      }
    }, [userData]
  )

  const resetState = async () => {
    await setUserOrNull(null)
    await setUserData(null)
    await setPublicData({})
  }

  const renderLearningPage = () => <Learning
    userData={userData}
    userOrNull={userOrNull}
    publicData={publicData}
  />

  const renderMain = () => <>
    {
      wrapInSuspense(
        <NavBar
          userOrNull={userOrNull}
          userData={userData}
          publicData={publicData}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setTheme={setTheme}
        />
      )
    }
    {/*{
      wrapInSuspense(
        (userOrNull === null) && <ChatCTA />
      )
    }*/}
    {renderRoutes()}
    {wrapInSuspense(<Footer userOrNull={userOrNull} userData={userData} />)}
  </>

  const withNavbarSpace = component => <div className={'pages-with-navbar-container'}>
      {component}
    </div>

  const renderRoutes = () => <>
    <Routes>
      {publicRoutes().map(r => r)}
      {adminRoutes().map(r => r)}
      {/*Default route*/}
      <Route
        index
        element={
          withNavbarSpace(wrapInSuspense(<Landing
            userOrNull={userOrNull}
            userData={userData}
            publicData={publicData}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />))
        }
      />
    </Routes>
  </>

  const adminRoutes = () => (userOrNull && userData && userData.isAdmin)
    ? [
      <Route
        key={'route-for-admin-chat'}
        path={"/admin-chat"}
        element={
          userData?.isAdmin
            ? withNavbarSpace(wrapInSuspense(
              <AdminChatContainer
                userOrNull={userOrNull}
                userData={userData}
              />
            ))
            : null
        }
      />,
      <Route
        key={'route-for-admin-panel'}
        path={"/admin"}
        element={
          userData?.isAdmin
            ? withNavbarSpace(wrapInSuspense(
              <AdminPanel
                users={adminUsersData}
                userOrNull={userOrNull}
                userData={userData}
              />
            ))
            : null
        }
      />,
    ]
    : []

  const publicRoutes = () => [
    <Route
      key={'route-for-terms-and-conditions'}
      path={"/terms-and-conditions"}
      element={withNavbarSpace(wrapInSuspense(<TermsAndConditions/>))}
    />,
    <Route
      key={'route-for-privacy-policy'}
      path={"/privacy-policy"}
      element={withNavbarSpace(wrapInSuspense(<PrivacyPolicy/>))}
    />,
    <Route
      key={'route-for-terms-and-conditions-of-supply'}
      path={"/terms-and-conditions-of-supply"}
      element={withNavbarSpace(wrapInSuspense(<TermsAndConditionsOfSupply/>))}
    />,
    <Route
      key={'route-for-acceptable-use'}
      path={"/acceptable-use"}
      element={withNavbarSpace(wrapInSuspense(<AcceptableUse/>))}
    />,
    <Route
      key={'route-for-press'}
      path={"/press"}
      element={withNavbarSpace(wrapInSuspense(<Press />))}
      exact
    />,
    <Route
      key={'route-for-approach'}
      path={"/approach"}
      element={withNavbarSpace(wrapInSuspense(<Approach />))}
      exact
    />,
    <Route
      key={'route-for-careers'}
      path={"/careers"}
      element={withNavbarSpace(wrapInSuspense(<Careers />))}
      exact
    />,
    <Route
      key={'route-for-payment'}
      path={"/payment"}
      element={withNavbarSpace(wrapInSuspense(<Payment />))}
      exact
    />,
    <Route
      key={'route-for-faq'}
      path={"/faq"}
      element={withNavbarSpace(wrapInSuspense(<FAQ />))}
      exact
    />,
    <Route
      key={'route-for-challenge-template'}
      path={"/page"}
      element={withNavbarSpace(wrapInSuspense(
        <Page />
      ))}
      exact
    />,
    <Route
      key={'route-for-challenge-template'}
      path={"/categories"}
      element={withNavbarSpace(wrapInSuspense(
        <CategoryList userData={userData} userOrNull={userOrNull} publicData={publicData} userCurrency={userCurrency}/>
      ))}
      exact
    />,
    <Route
      key={'route-for-how-it-works'}
      path={"/how-it-works"}
      element={withNavbarSpace(wrapInSuspense(
        <HowItWorks publicData={publicData} searchInput={searchInput} setSearchInput={setSearchInput} />
      ))}
      exact
    />,
    <Route
      key={'route-for-challenge-template'}
      path={"/categories/:category"}
      element={withNavbarSpace(wrapInSuspense(
        <CategoryList userData={userData} userOrNull={userOrNull} publicData={publicData} userCurrency={userCurrency}/>
      ))}
      exact
    />,
    /*<Route
      key={'route-for-stream'}
      path={"/stream"}
      element={withNavbarSpace(wrapInSuspense(
        <Stream userData={userData} userOrNull={userOrNull} publicData={publicData}/>
      ))}
      exact
    />,*/
    <Route
      key={'route-for-video-page'}
      path={"/v/:videoId"}
      element={withNavbarSpace(wrapInSuspense(
        <Video userData={userData} userOrNull={userOrNull} publicData={publicData}/>
      ))}
      exact
    />,
    <Route
      key={'route-for-confirm-and-book'}
      path={"/subjects/:subjectID/:pricingIndex"}
      element={withNavbarSpace(wrapInSuspense(
        <ConfirmAndBook
          userData={userData}
          userOrNull={userOrNull}
          publicData={publicData}
          userCurrency={userCurrency}
        />
      ))}
      exact
    />,
    <Route
      key={'route-for-learning'}
      path={"/learning/:subjectId/:learningId/:pageName"}
      element={wrapInSuspense(
        renderLearningPage()
      )}
      exact
    />,
    <Route
      key={'route-for-learning'}
      path={"/learning/:subjectId/:learningId"}
      element={wrapInSuspense(
        renderLearningPage()
      )}
      exact
    />,
    <Route
      key={'route-for-learning'}
      path={"/learning/:subjectId"}
      element={wrapInSuspense(
        renderLearningPage()
      )}
      exact
    />,
    <Route
      key={'route-for-subject-subject-id'}
      path={"/subjects/:subjectID"}
      element={withNavbarSpace(wrapInSuspense(
        <SubjectPage
          userData={userData}
          userOrNull={userOrNull}
          showDescription
          useTitle
          showTags
          publicData={publicData}
          setSearchInput={setSearchInput}
          userCurrency={userCurrency}
        />
      ))}
    />,
    <Route
      exact
      key={'route-for-subject-subject-id'}
      path={"/subjects/:subjectID/booking-complete"}
      element={withNavbarSpace(wrapInSuspense(
        <BookingCompletePage
          userData={userData}
          userOrNull={userOrNull}
          publicData={publicData}
        />
      ))}
    />,
    <Route
      key={'weekly-availability-calculator'}
      path={"/weekly-availability"}
      element={withNavbarSpace(wrapInSuspense(
        <WorkOutWeeklyAvailability
          availabilities={[
            1*11*60*60, // Monday at 11
            1*12*60*60, // Monday at 11
            2*11*60*60, // Tuesday at 11
            3*23*60*60, // Wednesday at 23
            4*0*60*60, // Thursday morning midnight
          ]}
          teacherClassTimeLocation={`Dublin, Ireland`}
          teacherName={'Alex'}
        />
      ))}
    />,
    <Route
      key={'weekly-availability'}
      path={"/set-weekly-availability"}
      element={withNavbarSpace(wrapInSuspense(
        <WeeklyAvailabilityChart settingMode={true} />
      ))}
    />,
    <Route
      key={'route-for-chat'}
      path={"/chat"}
      element={withNavbarSpace(wrapInSuspense(
        <ChatContainer
          userOrNull={userOrNull}
          userData={userData}
        />
      ))}
    />,
    <Route
      key={'route-for-story-book'}
      path={"/story-book"}
      element={withNavbarSpace(wrapInSuspense(
        <StoryBook
          publicData={publicData}
          setTheme={setTheme}
        />
      ))}
    />,
    <Route
      key={'route-for-dashboard'}
      path={"/d"}
      element={
        withNavbarSpace(wrapInSuspense(
          <Dashboard
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            userOrNull={userOrNull}
            userData={userData}
            publicData={publicData}
          />
        ))
      }
    />,
    <Route
      key={'route-for-auth'}
      path={"/auth"}
      element={
        withNavbarSpace(wrapInSuspense(
          <Auth
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            userOrNull={userOrNull}
            userData={userData}
            publicData={publicData}
          />
        ))
      }
    />
  ]

  const setUserEmailInDatabaseAndUpdateActivity = async (userOrNull, snapshotVal) => {
    if(!userOrNull) return
    try {
      if(!snapshotVal?.email) {
        recordAction('event', 'create-account-record-email', {uid: userOrNull.uid})
        await set(ref(db, `users/${userOrNull.uid}/email`), userOrNull.email)
      }
    }
    catch(e) {console.warn(`Error warning 14339`, e)}
  }

  const setUserCreatedTime = async (userOrNull, userDataSnapshot) => {
    if(!userOrNull) return
    if(!userDataSnapshot?.created) {
      try {
        recordAction('event', 'create-account-record-account-creation-time', {uid: userOrNull.uid})
        await set(ref(db, `users/${userOrNull.uid}/created`), Date.now())
      }
      catch(e) {console.warn(`Error warning 14338`)}
    }
  }

  const setFirebaseUserCurrency = async (userOrNull, snapshotVal) => {
    if(!userOrNull) return
    if(!snapshotVal?.currency || (snapshotVal?.currency && userCurrency !== snapshotVal.currency)) {
      try {
        await set(ref(db, `users/${userOrNull.uid}/currency`), userCurrency)
      }
      catch(e) {console.warn(`Error warning 14337`)}
    }
  }

  const authStateChange = async user => {
    const setFirebaseUserDataInState = async snapshotValue => {
      let valueToSet = snapshotValue || {}
      await setUserData(valueToSet)
    }

    const getIdToken = async () => {
      let res = await user?.getIdToken()
      setItem({name: 'userAuthToken', value: res})
    }

    if(!user) {
      setLoadingFirebaseData(false)
      return resetState()
    }
    else if(JSON.stringify(user) !== JSON.stringify(userOrNull)) {
      getIdToken()

      await setUserOrNull(user)
      setLoadingFirebaseData(true)
      onValue(
        ref(db, `users/${user.uid}`),
        async function(snapshot) {
          const snapshotVal = snapshot.val()
          await setUserEmailInDatabaseAndUpdateActivity(user, snapshotVal)
          await setFirebaseUserDataInState(snapshotVal)
          await setFirebaseUserCurrency(user, snapshotVal)
          await setUserCreatedTime(user, snapshotVal)
        }
      )
      setLoadingFirebaseData(false)
    }
  }

  onValue(
    ref(db, 'publicData'),
    async snapshot => {
      if(snapshot.val() && JSON.stringify(snapshot.val()) !== JSON.stringify(publicData)) {
        setShowAdvanceLoading(false)
        setPublicData(snapshot.val())
      }
    }
  )

  onValue(
    ref(db, 'users'),
    async snapshot => {
      if(snapshot.val() && JSON.stringify(snapshot.val()) !== JSON.stringify(adminUsersData)) {
        setAdminUsersData(snapshot.val())
      }
    }
  )

  const conditionallyCallAuthStateChange = user => {
    if(lastUserObject !== JSON.stringify(user)) {
      authStateChange(user)
    }
    lastUserObject = JSON.stringify(user)
  }

  onAuthStateChanged(
    auth, conditionallyCallAuthStateChange
  )

  return <Router>
    <ScrollToTop />
    <div id={'app'} className={`app`}>
      {
        showAdvanceLoading && <AdvanceLoading />
      }
        {
          renderMain()
        }
    </div>
  </Router>
}

export default App
