import { getStorage, ref, uploadString  } from "firebase/storage"
import {advancePost} from "../requests"

const recordActionInAdvanceServer = (...args) => {
	try {
		advancePost(
			`general/event`,
			{
				eventName: args[1],
				eventParams: args[2] || {},
			}
		)
	}
	catch(e) {
		console.warn(`Error recording event`)
	}
}

const uploadStringFileToFirebaseStorage = ({filePath, fileAsString, metadata}) => {
	const storage = getStorage()
	const storageRef = ref(storage, filePath)
	uploadString(storageRef, fileAsString, 'data_url', metadata)
}

const recordAction = (...args) => {
	recordActionInAdvanceServer(...args)
	return window.gtag ? window.gtag(...args) : null
}

export {recordAction, uploadStringFileToFirebaseStorage, recordActionInAdvanceServer}
